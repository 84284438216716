import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    redirect: "/home",
    component: () => import("../views/menu.vue"),
    children: [
      //首页
      {
        path: "/home",
        meta: {
          title: "首页",
        },
        component: () => import("../views/home"),
      },
      //短信服务
      //账号管理
      {
        path: "/accountManage",
        meta: {
          title: "主账号管理",
        },

        component: () => import("../views/message/accountManage"),
      },
      // 子账号管理
      {
        path: "/subAccountManage",
        meta: {
          title: "子账号管理",
          roleType:3
        },

        component: () => import("../views/message/subAccountManage"),
      },
      //充值记录
      {
        path: "/recharge",
        meta: {
          title: "充值记录",
        },

        component: () => import("../views/message/recharge"),
      },
      //短信发送
      {
        path: "/msgSend",
        meta: {
          title: "短信发送",
          roleType:3
        },

        component: () => import("../views/message/msgSend"),
      },
      //未知记录
      {
        path: "/sending",
        meta: {
          // title: "未知记录",
          // roleType: 2,
          title: "发送中记录",
          roleType: 3,
        },

        component: () => import("../views/message/sending"),
      },
      //已完成记录
      {
        path: "/success",
        meta: {
          title: "已完成记录",
          // roleType: 2,
          roleType: 3,
        },

        component: () => import("../views/message/success"),
      },
      //上行记录
      {
        path: "/upNotes",
        meta: {
          title: "上行记录",
          // roleType: 2,
          roleType: 3,
        },

        component: () => import("../views/message/upNotes"),
      },
      //签名管理
      {
        path: "/signManage",
        meta: {
          title: "签名管理",
          roleType: 3,
        },

        component: () => import("../views/message/signManage"),
      },
      //模板管理
      {
        path: "/formwork",
        meta: {
          title: "模板管理",
          roleType:3
        },

        component: () => import("../views/message/formwork"),
      },
      //统计管理
      {
        path: "/statistics",
        meta: {
          title: "统计管理",
        },

        component: () => import("../views/message/statistics"),
      },
      //二要素认证
      {
        path: "/transferForT",
        meta: {
          title: "二要素认证/调用记录",
        },

        component: () => import("../views/factor2/index"),
      },
      //三要素认证
      {
        path: "/transferForS",
        meta: {
          title: "三要素认证/调用记录",
        },

        component: () => import("../views/factor3/index"),
      },

      //  空号检测
      {
        path: "/vacantNumber",
        meta: {
          title: "空号检测/检测记录",
        },

        component: () => import("../views/vacantNumber/vacantNumber"),
      },
      //在网时长
      {
        path: "/onlineTime",
        meta: {
          title: "在网时长/调用记录",
        },

        component: () => import("../views/onlineTime/index"),
      },
      //运营商二要素
      {
        path: "/onlineType",
        meta: {
          title: "运营商二要素/调用记录",
        },

        component: () => import("../views/onlineType/index"),
      },
      //汇总统计
      //日统计
      {
        path: "/statisticsSun",
        meta: {
          title: "日统计",
        },

        component: () => import("../views/summaryStatistics/statisticsForSUN"),
      },
      //月统计
      {
        path: "/statisticsMoon",
        meta: {
          title: "月统计",
        },

        component: () => import("../views/summaryStatistics/statisticsForMoon"),
      },
      // 子账号统计
      {
        path: "/subAccStat",
        meta: {
          title: "子账号统计",
          roleType:3
        },
        component: () => import("../views/message/subAccountStatistics"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
// 白名单
const whiteList = ['login']

router.beforeEach((to, form, next) => {
  const token = localStorage.getItem('token')
  let userInfo = window.localStorage.getItem("userInfo");
  const user = JSON.parse(userInfo) || {};
  if(token) {
    if(to.name == 'login') {
      router.push({ name: "menu" });
    } else {
      if(user) {
        if(user.roleType == 3 && to.meta.roleType == 3) {
          next()
        } else if(user.roleType != 3){
          next()
        } else {
          next('/subAccountManage')
        }
      }
      
    }
  } else {
    Message.warning({message: "请登录！"});
    if(whiteList.indexOf(to.name) !== -1) {
      next()
    } else {
      router.push({name:'login'})
    }
  }
  // if (user.roleType == 3) {
  //   if (to.meta.roleType == 3) {
  //     next();
  //   } else {
  //     next("/sending");
  //   }
  // } else {
  //   if (to.name == "login") {
  //     if (window.localStorage.getItem("token")) {
  //       router.push({ name: "menu" });
  //     } else {
  //       next();
  //     }
  //   } else {
  //     if (window.localStorage.getItem("token")) {
  //       next();
  //     } else {
  //       Message.warning({
  //         message: "请登录！",
  //       });
  //       router.push({ name: "login" });
  //     }
  //   }
  // }
});

export default router;
